import React from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'
import SEO from '../components/seo'
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-custom'
import Footer from '../containers/layout/footer/footer-custom'
import PageHeader from '../components/pageheader'
import PrivacyPolicyArea from '../containers/privacy-policy-area'

const PrivacyPolicyPage = ({ pageContext, location }) => {
    const intl = useIntl()

    return (
        <Layout location={location}>
            <SEO
                title={intl.formatMessage({
                    id: 'page.privacyPolicy.title',
                })}
            />
            <Header />
            <PageHeader
                pageContext={pageContext}
                location={location}
                title={intl.formatMessage({
                    id: 'page.privacyPolicy.title',
                })}
            />
            <PrivacyPolicyArea />
            <Footer />
        </Layout>
    )
}

export default PrivacyPolicyPage
