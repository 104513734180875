import React from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'
import { useStaticQuery, graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Container, Row, Col } from '../../components/ui/wrapper'
import Anchor from '../../components/ui/anchor'
import { Content } from './privacy-policy.style'

const AnchorTag = props => <Anchor setNofollow {...props} />
const components = {
    a: AnchorTag,
}

const PrivacyPolicyArea = () => {
    const intl = useIntl()
    const privacyPolicyData = useStaticQuery(graphql`
        query PrivacyPolicyQuery {
            allMdx(
                filter: { fileAbsolutePath: { regex: "/privacy-policy.md/" } }
            ) {
                edges {
                    node {
                        body
                        frontmatter {
                            lang
                        }
                    }
                }
            }
        }
    `)

    const data = privacyPolicyData.allMdx.edges
    const content = data.filter(edge =>
        edge.node.frontmatter.lang.includes(intl.locale)
    )[0].node

    return (
        <Container pt="65px" pb="65px">
            <Row>
                <Col>
                    <Content>
                        <MDXProvider components={components}>
                            <MDXRenderer>{content.body}</MDXRenderer>
                        </MDXProvider>
                    </Content>
                </Col>
            </Row>
        </Container>
    )
}

export default PrivacyPolicyArea
